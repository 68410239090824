// src/components/Home.js
import React from 'react';
import Main from '../components/Index/Main/Main';

const Home = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default Home;
